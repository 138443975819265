<template>
    <div class="gamePlay">
        <div class="container">
            <img src="@/assets/images/Platinum.gif" alt="" class="mybGif" v-if="currentColorType == 'PLATINUM'" />
            <img src="@/assets/images/Gold.gif" alt="" class="mybGif" v-if="currentColorType == 'GOLD'" />
            <img src="@/assets/images/Silver.gif" alt="" class="mybGif" v-if="currentColorType == 'SILVER'" />
            <img src="@/assets/images/Bronze.gif" alt="" class="mybGif" v-if="currentColorType == 'BRONZE'" />
            <div class="typeData">
                <div class="typeItem">
                   <div class="typeList">
                       <p @click="checkColorType('PLATINUM')">
                            <img src="../../assets/images/Platinum.png" alt="">
                            <span>PLATINUM</span>
                        </p>
                        <p @click="checkColorType('GOLD')">
                            <img src="../../assets/images/Gold.png" alt="">
                            <span>GOLD</span>
                        </p>
                        <p @click="checkColorType('SILVER')">
                            <img src="../../assets/images/Silver.png" alt="">
                            <span>SILVER</span>
                        </p>
                        <p @click="checkColorType('BRONZE')">
                            <img src="../../assets/images/Bronze.png" alt="">
                            <span>BRONZE</span>
                        </p>
                   </div>
                    <div class="Term">
                        <ul>
                            <li>Terms & Conditions:</li>
                            <li>1. Total of 10,000 Genesis horses in the wave 1 whitelist Sale.</li>
                            <li>2. Wave 1 whitelist places guarantee a 15% discount on public sale price.</li>
                            <li>3. Wave 1 whitelist places allow the purchase of up to 2 Mystery Boxes per wallet.</li>
                            <li>4. Wave 1 whitelist opens on (day), (month) 2022. Join our Discord for entry details.</li>
                        </ul>
                    </div>
               </div>
               <div class="typeface">
                  <img src="../../assets/images/TypeFace.png" alt="" class="faceImg" />
                   <p class="original">ORIGINAL PRICE 
                       <span>{{ soldPrice }} USDT</span>
                   </p>

                    <el-button 
                    :loading="isDisabled"
                    :disabled="isDisabled || !!isSoldOut || !!isLimitBuy"
                    :style="isDisabled || !!isSoldOut || !!isLimitBuy ? {backgroundColor: '#ccc'} : {}"
                    @click="buyAndOpenFun">
                        <span v-if="isSoldOut">Sold out</span>
                        <span v-else>{{discountPrice}} USDT</span>
                        
                    </el-button>

                   <div class="limitCount">
                        <span class="count">{{limitCount}}</span>
                        <span class="rightText">Mystery Box Purchases Remaining</span>
                    </div>
                   
                    
                   <p class="mysterious">Which horse is inside your Mystery Box?</p>
                   <p class="balanceBox">
                       <span>BALANCE</span>
                       <span>{{boxCount}}</span>
                   </p>
                   <div class="Horse">
                       <div class="one" v-for="item in rateArr" :key="item.id">
                           <div class="percent">{{item.percent}}%</div>
                            <img :src='item.img' alt="" class="horseImg" />
                            <span class="darley">{{item.name}}</span>
                       </div>
                   </div>
               </div>
            </div>
            <div class="popout" v-if="showArea">
                <div class="popout_Left">
                    <p class="congratulation">
                        <i>CONGRATULATIONS</i>
                    </p>
                    <p class="darley_arabian">{{res_name}}</p>
                    <p class="six">All {{starNums.length}} attributes maxed out!</p>
                    <ul>
                        <li v-for="(item, index) in starNums" :key="index">
                            <img src="../../assets/images/star.png" alt="">
                        </li>
                    </ul>
                    <img :src="require(`@/assets/images/${res_name}-charts.png`)" alt="" class="chart">
                </div>
                <div class="popout_right">
                    <img :src="require(`@/assets/images/${res_name}-horse.png`)" alt="" class="popout_horse">
                    <img src="../../assets/images/label.png" alt="" class="label">
                    <div class="numBox">#{{res_num}}</div>
                </div>
                <div class="off" @click="off">
                    <img src="../../assets/images/off.png" alt="">
                </div>
            </div>
        </div>
    </div>
  
</template>
<script>
import MetaRaceSDK from './../../utils/MetaRaceSDK'
import { v4 as uuidv4 } from 'uuid';
import Darley from '../../assets/images/Darley (1).png';
import GodolphinArabian from '../../assets/images/GodolphinArabian.png'
import Byerly_turk from '../../assets/images/Byerly_turk.png';
import NakamotoSatoshi from '../../assets/images/NakamotoSatoshi.png';
import {eventsBus} from '../../main'
export default {
    data() {
        return{
            showArea:false,
            currentColorType: 'PLATINUM',
            rateArr:[
                {
                    "id":1,
                    "percent": '',
                    "img":Darley,
                    "name":'DARLEY ARABIAN',
                },
                {
                    "id":2,
                    "percent": '',
                    "img":GodolphinArabian,
                    "name":'GODOLPHIN ARABIAN',
                },
                {
                    "id":3,
                    "percent": '',
                    "img":Byerly_turk,
                    "name":'BYERLY TURK',
                },
                {
                    "id":4,
                    "percent": '',
                    "img":NakamotoSatoshi,
                    "name":'NAKAMOTO SATOSHI',
                },
            ],
            soldPrice: 0,
            discountPrice: 0,
            probability: 0,
            uuid: '',
            isDisabled: false,
            res_num: 0,
            res_name: 'NAKAMOTO SATOSHI',
            starNums: [],
            isSoldOut: true,
            isLimitBuy: true,
            limitCount: 0,
            boxCount: 0
        }
    },
    created(){
        this.initData(this.currentColorType)
        eventsBus.$on('initBoxData', this.initData)
        eventsBus.$on('checkColorType', this.checkColorType)
    },
    destroyed() {
        this.$nuxt.$off('initBoxData')
        this.$nuxt.$off('checkColorType')
    },
    methods: {
        async initData(colorType) {
            let {account, env, chainId, msg} = await MetaRaceSDK.CheckStatus()
            if(account && typeof(msg) === 'string'){
                this.$message.error(msg);
                return false
            }else{
                let price = await MetaRaceSDK.GetSoldPrice(colorType)
                this.soldPrice = Number(price/10000) || 0
                let discountPrice = await MetaRaceSDK.GetDiscountPrice(colorType)
                this.discountPrice = Number(discountPrice/10000) || 0
                this.rateArr.map(async (item, index) => {
                    let probability = await MetaRaceSDK.GetProbability(colorType, this.rateArr[index].name.replace(' ', '_'))
                    if(!probability.code) this.rateArr[index].percent = Number(probability/100)
                })
                this.isSoldOut = await MetaRaceSDK.IsSoldOut(colorType)
                this.isLimitBuy = await MetaRaceSDK.IsLimitBuy(account) 
                let limitCount = await MetaRaceSDK.GetLimitCount(account)
                this.limitCount = Number(limitCount[0]) || 0
                let boxCount = await MetaRaceSDK.GetBoxCount(colorType)
                this.boxCount = Number(boxCount[0]) || 0
                this.uuid = uuidv4()
            }
        },
        async resCallback(res) {
            if(res.code === 1){
                let result = await MetaRaceSDK.GetOpenResult(this.uuid)
                let num = Number(result[0])
                let name = result[1][0].split(',')[1]
                
                if(num && name){
                    this.res_num = num
                    this.res_name = name === 'SATOSHI_NAKAMOTO' ? 'NAKAMOTO SATOSHI' : name.replace('_', ' ').toUpperCase()
                    switch (name) {
                        case 'DARLEY_ARABIAN':
                            this.starNums.length = 6
                            break;
                        case 'GODOLPHIN_ARABIAN':
                            this.starNums.length = 5
                            break;
                        case 'BYERLY_TURK':
                            this.starNums.length = 4
                            break;
                        case 'SATOSHI_NAKAMOTO':
                            this.starNums.length = 3
                            break;
                    
                        default:
                            break;
                    }
                    this.showArea = true
                    this.isDisabled = false
                }
            }else{
                this.isDisabled = false
            }
            this.uuid = ''
        },
        changeTipsText(text) {
            this.tipText = text
        },
        async buyAndOpenFun(){
            this.changeTipsText('Loading...')
            this.isDisabled = true
            try {
                await MetaRaceSDK.BuyAndOpen(this.uuid, this.currentColorType, this.resCallback, this.changeTipsText)
            } catch (error) {
                console.log(error)
                this.$message.error("Buy failed")
                this.isDisabled = false
            }
        },
        off(){
            this.initData(this.currentColorType)
            this.showArea = false
        },
        async checkColorType(type){
            this.currentColorType=type;
            this.isSoldOut = await MetaRaceSDK.IsSoldOut(type)
            let boxCount = await MetaRaceSDK.GetBoxCount(type)
            this.boxCount = Number(boxCount[0]) || 0
            this.initData(type)
        }
    }
}
</script>

<style  lang="scss" scoped>
.gamePlay{
    width:100%;
    height: 816px;
    box-sizing: border-box;
    background: url('../../assets/images/Layer 1.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    overflow: hidden;
    .container{
        width: 1200px;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .mybGif{
            width: 567px;
            height: 567px;
            position: absolute;
            top: 102px;
            left: 216px;
        }
        .PlatinumBig{
            width: 484px;
            height: 512px;
            position: absolute;
            top: 152px;
            left: 216px;
            z-index:50;
            img{
                width: 99%;
                height: 99%;
            }
        }
        .typeData{
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 99;
            .typeItem{
               .typeList{
                    p{
                        width: 216px;
                        height: 43px;
                        background: #C021F4;
                        border-radius: 10px;
                        margin-top: 37px;;
                        margin-left: 34px;
                        position: relative;
                        padding-left: 65px;
                        box-sizing: border-box;
                        cursor: pointer;
                        span{
                            font-size: 24px;
                            font-family:Rubik-BoldItalic;;
                            font-weight: Bold Italic;
                            color: #FFFFFF;
                            line-height: 45px;
                        }
                        img{
                            width: 90px;
                            height: 96px;
                            position: absolute;
                            top: -31px;
                            left: -35px;
                        }
                    }
               }
               .Term{
                    width: 327px;
                    height: 380px;
                    background: #300856;
                    border-radius: 20px;
                    margin-top: 50px;
                    ul{
                        padding-top: 43px;
                        padding-left: 27px;
                    }
                    ul li{
                            list-style-type: none;
                            width: 250px;
                            font-size: 15px;
                            font-family: Rubik-MediumItalic;
                            font-weight: normal;
                            color: #FF00EA;
                            margin-bottom: 18px;
                    }
                }
            }
            .typeface{
                .faceImg{
                    width: 648px;
                    height: 136px;
                    margin-top: 50px;
                    z-index:50;
                    position:absolute;
                    right: 0;
                }
                .limitCount{
                    display: inline-flex;
                    margin-left: 410px;
                    margin-top: 14px;
                    font-size: 24px;
                    font-family: Rubik-BoldItalic;
                    .count{
                        display: inline-flex;
                        min-width: 44px;
                        padding: 12px;
                        height: 44px;
                        border: 2px solid #FF00EA;
                        border-radius: 10px;
                        justify-content: center;
                        align-items: center;
                        box-sizing: border-box;
                    }
                    .rightText{
                        display: inline-flex;
                        max-width: 186px;
                        color: #FF00EA;
                        font-size: 16px;
                        margin-left: 12px;
                        align-items: center;
                    }
                }
                .original{
                    font-size: 18px;
                    font-family: Rubik-BoldItalic;
                    color: #FFFFFF;
                    line-height: 27px;
                    margin-left: 410px;
                    margin-top: 232px;

                    span{
                        display: inline-block;
                        position: relative;
                    }

                    span::after{
                        display: inline-block;
                        content: '';
                        width: 100%;
                        height: 1px;
                        border-top: 2px solid #FF0000;
                        position: absolute;
                        top: 12px;
                        left: 0;
                        transform:rotate(-5deg);
                        -ms-transform:rotate(-5deg); 	/* IE 9 */
                        -moz-transform:rotate(-5deg); 	/* Firefox */
                        -webkit-transform:rotate(-5deg); /* Safari 和 Chrome */
                        -o-transform:rotate(-5deg); 	/* Opera */
                    }

                }
                    
                button{
                    min-width: 178px;
                    height: 45px;
                    background: #FF00EA;
                    border-radius: 10px;
                    font-size: 28px;
                    font-family: Rubik-BoldItalic;
                    color: #FFFFFF;
                    line-height: 45px;
                    margin-top: 12px;
                    margin-left: 410px;
                    padding: 0 17px;
                    border:none;
                    cursor: pointer;
                }
                .mysterious{
                    font-size: 18px;
                    font-family: Rubik-Italic;
                    color: #FFFFFF;
                    line-height: 27px;
                    margin-left: 410px;
                    margin-top: 14px;
                }
                
                .balanceBox{
                    display: flex;
                    margin-left: 410px;
                    margin-top: 9px;
                    height: 44px;
                    font-family: Rubik-MediumItalic;
                    font-size: 18px;
                    span{
                        height: 44px;
                        line-height: 44px;
                        text-align: center;
                        border: 2px solid #FF00EA;
                        box-sizing: border-box;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }
                    span:first-child{
                        width: 109px;
                        background: #FF00EA;
                        border: 2px solid #FF00EA;
                        border-right: 0;
                        border-radius: 10px 0px 0px 10px;
                    }
                    span:last-child{
                        min-width: 108px;
                        font-size: 24px;
                        border-left: 0;
                        border-radius: 0px 10px 10px 0px;
                        padding: 12px;
                    }
                }
                .Horse{
                   width: 580px;
                   height: 200px;
                   display: flex;
                   margin-left:290px;
                   margin-top: 60px;
                   .one{
                       width: 136px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-left: 15px;
                        .percent{
                            font-family: Rubik-BoldItalic;
                        }
                        .horseImg{
                            width: 136px;
                            height: 141px;
                            margin-top: 28px;
                        }
                        .darley{
                            width: 77px;
                            font-size: 13px;
                            text-align: center;
                            font-family: Rubik-BoldItalic;
                            word-break: keep-all;
                        }
                   }
                }
            }
        }
        .popout{
            width: 1138px;
            height: 588px;
            position:absolute;
            top: 50%;
            left: 50%;
            z-index: 99;
            transform:translateX(-50%) translateY(-54%);
            border-radius: 15px;
            display: flex;
            justify-content: space-between;
            background:#000000;
            .popout_Left{
                padding-left: 40px;
                .congratulation{
                    width: 553px;
                    height: 43px;
                    font-size: 59px;
                    font-family: Rubik-BoldItalic;
                    font-weight: Bold Italic;
                    color: #DD9A00;
                    line-height: 51px;
                    padding-top:65px;
                }
                .darley_arabian{
                    min-width: 412px;
                    height: 33px;
                    font-size: 45px;
                    font-family: Rubik-BoldItalic;
                    font-weight: Bold Italic;
                    color: #FFFFFF;
                    line-height: 39px;
                    padding-top: 46px;
                }
                .six{
                    width: 160px;
                    height: 10px;
                    font-size: 12px;
                    font-family: Rubik-MediumItalic;
                    margin-top: 18px;
                    font-weight: Medium Italic;
                    color: #FFFFFF;
                }
                ul{
                    display: flex;
                    margin-top: 23px;
                    li{
                        img{
                            width: 28px;
                            height: 25px;
                        }
                    }
                }
                .chart{
                    width: 331px;
                    height: 243px;
                    margin-top: 45px;
                }
            }
            .popout_right{
                position: relative;
                .popout_horse{
                    width: 520px;
                    height: 520px;
                    top: -35px;
                    left: -315px;
                    z-index:99;
                    position: absolute;
                }
                .label{
                    width: 196px;
                    height: 310px;
                    top: -25px;
                    left: 10px;
                    z-index:1;
                    position:absolute;
                }
                .numBox{
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    top: 60px;
                    left: -5px;
                    z-index: 90;
                    font-size: 60px;
                    width: 225px;
                    height: 62px;
                    line-height: 50px;
                    word-break: break-word;
                    font-family: Rubik-BoldItalic;
                    color: #FFFFFF;
                    transform: rotate(-90deg);
                }
            }
        }
        .off{
            width: 50px;
            height: 50px;
            cursor: pointer;
            img{
                width: 85%;
                height:85%;
            }
        }
        .VectorSmart{
            width: 302px;
            height: 303px;
            position: absolute;
            top: 65px;
            left: 280px;
            z-index: 30;
        }
    }
}

</style>